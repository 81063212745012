<template>
  <div class="main-content">
    <div class="content-view">
      <!-- 头 -->
      <div class="top" :style="{backgroundColor:isSkin}">
        <div class="thumb-title">关于我们</div>
      </div>
      <!-- 内容 -->
      <div class="content">
        <div class="left">
          <leftItem v-for="(item,index) in list" :key="index" :index="Number(isWe)" :cur="index" :item="item" @cilckMethod="cilckMethod"></leftItem>
        </div>
        <div class="right">
          <div class="one" v-if="content != null " v-html="content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftItem from './components/leftItem-box'
export default {
	components:{leftItem},
	data() {
		return {
			content:'',
			list:[{title:'公司领导'},{title:'公司简介'},{title:'发展历史'},{title:'公司荣耀'},{title:'结构介绍'},{title:'企业文化'},{title:'用户协议'},{title:'隐私政策'}],
		}
	},
	created(){
		this.$store.commit('changClick',1)
		var skin = sessionStorage.getItem('Skin')
		var zskin = sessionStorage.getItem('ZSkin')
		if (skin) {
			this.$store.commit('changSkin',skin)
		}
		if (zskin) {
			this.$store.commit('changZSkin',zskin)
		}
		this.getInformation(Number(this.isWe)+1)
	},
	computed:{
		isSkin(){
			return this.$store.state.isSkin
		},
		isWe(){
			return this.$store.state.isWeIndex
		}
	},
	methods:{
		/**关于我们**/
		getInformation(index){
			var that = this
			that.$api.getInformation({
				params:{
					category:index
				}
			}).then(res=>{
				if (res.data.code == 0) {
					that.content = res.data.data.content
				}else{
					that.content = ''
				}
			})
		},
		/**菜单点击按钮**/
		cilckMethod(index){
		  this.$store.commit('changWe',index.cur)
		  this.getInformation(index.cur+1)
		  sessionStorage.setItem('changWe',index.cur)
		},
	}
}
</script>

<style scoped lang="scss">
.main-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  .content-view{
    width: 1200px;
    padding-top: 50px;
    .top{
      width: 100%;
      height: 55px;
      border-radius: 4px;
      line-height: 55px;
      .thumb-title{
        font-size: 18px;
        color: #FFFFFF;
        margin-left: 12px;
      }
    }
    .content{
      display: flex;
      justify-items: center;
      align-items: flex-start;
      padding-top: 20px;
      padding-bottom: 150px;
      .left{
        width: 285px;
        background: #FCFCFC;
        padding: 0px 0px 10px 0px;
      }
      .right{
        width: 895px;
        margin-left: 20px;
        overflow: hidden;
        .one{
          font-size: 15px;
          line-height: 20px;
          color: #333333;
          text-indent:32.4px;
        }
        .two{
          margin-top: 40px;
        }
        .three{
          font-size: 15px;
          line-height: 20px;
          text-align: justify;
          margin-top: 40px;
          color: #333333;

        }
      }
    }
  }

}
</style>